<template>
  <div>
    <VTitle :title="$t('app.import_trainings')" class="route-title" />

    <VCard>
      <template #content>
        <VAlert>
          <!-- eslint-disable-next-line -->
          <span v-html="$t('app.no_import_trainings')" />
        </VAlert>
      </template>
    </VCard>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
// Components
import VTitle from "@/components/VTitle";
import VAlert from "@/components/VAlert";
import VCard from "@/components/VCard";

export default {
  components: {
    VTitle,
    VAlert,
    VCard
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Constants
    // eslint-disable-next-line
    const documentTitle = `${t("app.import_trainings")} - ${t("app.administration")}`;

    return {
      documentTitle
    };
  }
};
</script>
